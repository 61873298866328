import reviewCard from "../../../assets/images/review.png";
import animatedBg from "../../../assets/images/animated-bg.png";
import { Sparkles } from "../../../assets/svg";

const Review = () => {
  return (
    <section className="relative sm:h-[800px] xs:h-[600px] h-[400px]  flex justify-center">
      <img
        src={animatedBg}
        className="w-full  relative mt-[-120px]"
        alt="animated background"
      />
      <div className="w-full   flex flex-col items-center absolute sm:top-20 ">
        <h3 className="text-center sm:text-3xl text-xl text-white flex items-center gap-2 mb-3 mt-10">
          <Sparkles /> Our users love us
        </h3>

        <img src={reviewCard} alt="reviews" className="max-w-[700px] w-[80%]" />
      </div>
    </section>
  );
};

export default Review;
