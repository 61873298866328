export const privacyData = [
  {
    title: "Privacy Policy Summary",
    content:
      "At Asapuu, your privacy is our priority. This Privacy Policy outlines how we collect, use, and protect your personal information. By using our services, you consent to the practices described here. We strive to maintain the highest standards of security to ensure that your information remains safe and accessible only by authorized personnel. Your rights regarding your personal information are also detailed below.",
  },
  {
    title: "1.0 Collection of Personal Information",
    content:
      "We may collect personal information from you in various ways, including:",
    list: [
      "When you use our website or mobile application",
      "When you contact us via email, phone, or mail",
      "When you provide information to us in person",
    ],
  },
  {
    title: "2.0 Types of Personal Information Collected",
    content: "We may collect the following types of personal information:",
    list: [
      "Name",
      "Email address",
      "Phone number",
      "Mailing address",
      "Payment information (if applicable)",
    ],
  },
  {
    title: "3.0 Use of Personal Information",
    content: "We use your personal information for the following purposes:",
    list: [
      "Providing services and support",
      "Processing transactions",
      "Communicating with you",
      "Marketing and promotional purposes",
      "Improving our services",
    ],
  },
  {
    title: "4.0 Disclosure of Personal Information",
    content: "We may disclose your information to:",
    list: [
      "Affiliated companies",
      "Service providers",
      "Third-party vendors",
      "Law enforcement agencies (as required by law)",
    ],
  },
  {
    title: "5.0 Security Measures",
    content:
      "We implement reasonable security measures to protect your information, including:",
    list: ["Encryption", "Firewalls", "Secure Socket Layer (SSL) technology"],
  },
  {
    title: "6.0 Data Retention",
    content:
      "We retain your information for as long as necessary to provide services and support.",
  },
  {
    title: "7.0 Your Rights",
    content: "You have the right to:",
    list: [
      "Access your personal information",
      "Correct or update your information",
      "Request deletion of your information",
      "Opt-out of marketing communications",
    ],
  },
  {
    title: "8.0 Changes to This Policy",
    content:
      "We reserve the right to update this policy at any time. Any changes will be posted on this page.",
  },
];
