import { Helmet } from "react-helmet-async";
import Hero from "./Hero";
import Offers from "./Offers";
import Review from "./Review";

const Home = () => {
  return (
    <main>
      <Helmet prioritizeSeoTags>
        <title>Asapuu | Home</title>
        <meta
          name="Asapuu"
          content="Simple crypto trading.Buy and sell crypto, giftcards and other assets fast, easy, and at the best rates"
        />
        <meta
          name="description"
          content="Simple crypto trading.Buy and sell crypto, giftcards and other assets fast, easy, and at the best rates"
        />
        <meta property="og:title" content="Asapuu" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.asapuu.com" />
        <meta
          property="og:description"
          content="Simple crypto trading.Buy and sell crypto, giftcards and other assets fast, easy, and at the best rates"
        />
        <link rel="canonical" href="https://www.asapuu.com" />
      </Helmet>
      <Hero />
      <Review />
      <Offers />
    </main>
  );
};

export default Home;
