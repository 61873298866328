import Iphone from "../../../assets/images/device-addons.png";
import AppStoreBtns from "../../../components/AppStoreBtns";

const Hero = () => {
  return (
    <section className="flex flex-col xs:gap-3 gap-10 items-center px-5 mt-5">
      <div className=" text-center flex flex-col items-center gap-5 xs:gap-10 mt-10 px-5 xs:px-0">
        <h1 className="font-mabry-bold xs:text-7xl md:text-8xl  text-4xl">
          Simple crypto trading
        </h1>
        <p className=" md:text-3xl xs:text-2xl text-base md:leading-10">
          Buy and sell crypto, giftcards and other assets fast,
          <br className=" md:block hidden" /> easy, and at the best rates
        </p>
        <AppStoreBtns />
      </div>
      <div className="xs:max-w-[70%] max-w-[80%] lg:h-[500px] xs:h-[300px]  h-[250px] overflow-hidden">
        <img src={Iphone} alt="Iphone add ons" className=" relative" />
      </div>
    </section>
  );
};

export default Hero;
