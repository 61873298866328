import { Helmet } from "react-helmet-async";
import PageHero from "../../components/PageHero";
import { privacyData } from "./privacy";

const PrivacyPolicy = () => {
  return (
    <main className="mb-80">
      <Helmet prioritizeSeoTags>
        <title>Asapuu | Privacy Policy</title>
        <meta
          name="description"
          content="Learn how Asapuu collects, uses, and protects your personal information. Read our Privacy Policy to understand your rights and how we ensure your data is secure."
        />
        <meta property="og:title" content="Asapuu Privacy Policy" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.asapuu.com/privacy-policy"
        />
        <meta
          property="og:description"
          content="Learn how Asapuu collects, uses, and protects your personal information. Read our Privacy Policy to understand your rights and how we ensure your data is secure."
        />
        <link rel="canonical" href="https://www.asapuu.com/privacy-policy" />
      </Helmet>
      <PageHero title="Privacy Policy" info="Last updated October 6th, 2024." />

      <section className="container mx-auto px-5 flex flex-col gap-5 my-20 text-lg">
        {privacyData.map((section, index) => (
          <div key={index}>
            <h2 className="text-primary">{section.title}</h2>
            <p className="text-sm">{section.content}</p>
            {section.list && (
              <ul className="list-disc ml-5">
                {section.list.map((item, idx) => (
                  <li key={idx} className="text-sm">
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </section>
    </main>
  );
};

export default PrivacyPolicy;
