export const companyProducts = [
  {
    label: "Crypto",
    key: "1",
  },
  {
    label: "Giftcard",
    key: "2",
  },
  {
    label: "Bill Payments",
    key: "3",
  },
];

export const companyRoutes = [
  { label: "Home", key: "home", path: "/" },
  { label: "About", key: "about", path: "/about" },
  { label: "Privacy Policy", key: "privacy-policy", path: "/privacy-policy" },
  {
    label: "Terms and Conditions",
    key: "terms-and-conditions",
    path: "/terms-and-conditions",
  },
];
