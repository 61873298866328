export const termsData = [
  {
    title: "1.0 Definitions",
    content: [
      `"Agreement" refers to these Terms and Conditions that outline the responsibilities and rights of both the Company and the Customer.`,
      `"Company" denotes ASAPUU INVESTMENT COMPANY, the entity providing the services described herein.`,
      `"Customer" signifies any individual or entity that utilizes the Company's services.`,
      `"Services" encompasses all products and services offered by the Company to the Customer, as detailed on the Company's website or service agreement.`,
    ],
  },
  {
    title: "2.0 Acceptance",
    content: [
      "By using the Company's services, you acknowledge that you have read, understood, and agree to be bound by this Agreement. Your continued use signifies acceptance of any changes made to these terms.",
    ],
  },
  {
    title: "3.0 Services",
    content: [
      "The Company will provide services as described on its website or in service agreements. The Company retains the right to modify or discontinue any service at its discretion and without prior notice, which may include changes for maintenance, upgrades, or other operational needs.",
    ],
  },
  {
    title: "4.0 Payment Terms",
    content: [
      "Payment is required upon receipt of an invoice for services rendered. Late payments may incur interest charges at a specified rate per annum, which will be disclosed in the invoice or service agreement.",
    ],
  },
  {
    title: "5.0 Intellectual Property",
    content: [
      "All intellectual property rights, including content, trademarks, and proprietary materials, belong solely to the Company. The Customer agrees not to reproduce, distribute, or display any of the Company's materials without prior written permission.",
    ],
  },
  {
    title: "6.0 Confidentiality",
    content: [
      "The Company commits to maintaining the confidentiality of all Customer information. Likewise, Customers are required to keep any sensitive Company information confidential and not disclose it unless legally mandated to do so.",
    ],
  },
  {
    title: "7.0 Liability",
    content: [
      "The Company shall not be liable for any indirect, special, or consequential damages arising from the use of its services. The liability of the Company, if any, will be limited to a specified amount or as dictated by applicable law.",
    ],
  },
  {
    title: "8.0 Termination",
    content: [
      "Either party may terminate this Agreement by providing a written notice at least three months in advance. Upon termination, the Customer is obligated to settle all outstanding fees and payments.",
    ],
  },
  {
    title: "9.0 Governing Law",
    content: [
      "This Agreement shall be governed by the laws of the specified jurisdiction, without regard to any conflict of laws principles that may apply.",
    ],
  },
  {
    title: "10.0 Dispute Resolution",
    content: [
      "In the event of a dispute, either party may notify the other in writing. Both parties agree to meet and attempt to resolve the issue within seven days. If the dispute remains unresolved, the parties will pursue alternative dispute resolution methods such as mediation or arbitration, in accordance with the laws of Lagos State, prior to seeking court intervention.",
    ],
  },
  {
    title: "11.0 Changes to Terms",
    content: [
      "The Company reserves the right to amend these Terms and Conditions at any time. Customers will be responsible for reviewing any changes to stay informed about their rights and obligations.",
    ],
  },
  {
    title: "12.0 Contact",
    content: [
      "For any questions or concerns regarding these Terms and Conditions, please contact us at support@asapuu.com.",
    ],
  },
];
