import cashout from "../../../assets/images/cashout.png";
import premium from "../../../assets/images/premium-rate.png";
import secured from "../../../assets/images/secured-trade.png";
import Offer from "./Offer";

const Offers = () => {
  const offers = [
    {
      title: "Instant cashout",
      info: "Funds can be withdrawn instantly to your bank.",
      image: cashout,
    },
    {
      title: "Premium rates",
      info: "Competitive market rates, we give the best.",
      image: premium,
    },
    {
      title: "Secured trades",
      info: "All trades with us are encrypted.",
      image: secured,
    },
  ];
  return (
    <section className="flex flex-col items-center  mx-auto py-10  2xl:max-w-[70%] md:max-w-[80%] max-w-[90%] xs:mb-[400px]   bg-[#F7F7F7]">
      <h3 className=" text-4xl sm:text-6xl mb-10">Why choose us</h3>

      <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
        {offers.map((offer, index) => (
          <Offer key={index} {...offer} />
        ))}
      </div>
    </section>
  );
};

export default Offers;
