import { Dropdown, Space } from "antd";
import logo from "../../assets/images/small-logo.png";
import {
  DownOutlined,
  ArrowRightOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import NavDrawer from "./NavDrawer";
import { companyRoutes, companyProducts } from "./navbar";

const Products = () => {
  const items = companyProducts.map((product) => ({
    label: product.label,
    key: product.key,
  }));

  return (
    <Dropdown menu={{ items }}>
      <Space className="cursor-pointer sm:text-xl">
        Products
        <DownOutlined className="w-3" />
      </Space>
    </Dropdown>
  );
};

const Company = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const pathMap = companyRoutes.reduce((map, item) => {
    map[item.key] = item.path;
    return map;
  }, {});

  const handleMenuClick = (e) => {
    const targetPath = pathMap[e.key];
    if (targetPath && pathname !== targetPath) {
      navigate(targetPath);
    }
  };

  const companies = companyRoutes.map(({ label, key }) => ({
    label,
    key,
  }));

  return (
    <Dropdown
      menu={{ items: companies, onClick: handleMenuClick }}
      placement="bottomRight"
    >
      <Space className="cursor-pointer sm:text-xl">
        <p>Company</p>
        <DownOutlined className="w-3" />
      </Space>
    </Dropdown>
  );
};

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <header className="container mx-auto xs:py-8 py-5 xs:px-0 px-5 ">
        <div className="flex justify-between items-center">
          <div>
            <Link to={"/"}>
              <img src={logo} alt="Asapuu logo" className=" sm:w-32 w-28" />
            </Link>
          </div>
          <div className=" gap-10 xs:flex hidden">
            <Products />
            <Company />
          </div>
          <div className="xs:flex hidden">
            <p className="sm:text-xl">
              Download Now <ArrowRightOutlined />
            </p>
          </div>
          <MenuOutlined className="xs:hidden block" onClick={showDrawer} />
        </div>
      </header>
      <NavDrawer onClose={onClose} logo={logo} open={open} />
    </>
  );
};

export default Navbar;
