import PropTypes from "prop-types";

const Offer = ({ image, title, info }) => {
  return (
    <div className=" rounded-[50px] shadow-md border p-10 min-h-[250px] bg-white">
      <img src={image} alt={title} className=" w-20 sm:w-32" />
      <h4 className="font-mabry-bold text-xl sm:text-3xl">{title}</h4>
      <p className=" text-base sm:text-2xl font-mabry-light">{info}</p>
    </div>
  );
};

Offer.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string,
};

export default Offer;
