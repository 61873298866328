import { useNavigate, useRouteError } from "react-router-dom";
import notFound from "../../assets/svg/not-found.svg";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const Error = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <div className={`bg-gray-100 mb-60`}>
        <div className="container flex flex-col items-center py-10 gap-4 justify-center m-auto h-full">
          <h3 className=" text-3xl font-mabry-bold">
            {error?.statusText ? error?.statusText : "Error"}
          </h3>
          <p>{error && error?.data.replace("Error: ", "")}</p>
          <p>
            Click this{" "}
            <span
              className=" underline text-center font-mabry-bold  cursor-pointer"
              onClick={() => navigate(-1)}
            >
              link
            </span>{" "}
            to go back
          </p>
          <img
            src={notFound}
            alt="not-found"
            className="  w-[250px] sm:w-[400px] mt-6"
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Error;
