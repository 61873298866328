import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import HomeLayout from "./components/HomeLayout";
import Error from "./pages/Notfound";
import PrivacyPolicy from "./pages/Privacy";
import TermsAndConditions from "./pages/Terms";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeLayout />,
      errorElement: <Error />,
      children: [
        { index: true, element: <Home /> },
        {
          path: "/about",
          element: <About />,
        },
        { path: "/privacy-policy", element: <PrivacyPolicy /> },
        { path: "/terms-and-conditions", element: <TermsAndConditions /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
