export const footerItems = [
  { title: "PRODUCTS", items: ["Crypto", "Giftcard", "Bills Payments"] },
  {
    title: "COMPANY",
    items: ["About", "Privacy Policy", "Terms and Conditions"],
    link: true,
  },
  {
    title: "CONTACT US",
    items: [
      "36, ILADO road akesan igando, Lagos, Nigeria.",
      "+234 810 551 3417",
      "support@asapuu.com",
    ],
  },
];
