import FooterItem from "./FooterItem";
import logo from "../../assets/images/logo.png";
import { footerItems } from "./footer";
import { Facebook, Instagram, Twitter } from "../../assets/svg";
import FooterCard from "./FooterCard";

const Footer = () => {
  return (
    <footer className="bg-primary min-h-[700px] flex items-end relative mt-44">
      <FooterCard />
      <div className="container mx-auto py-20 pt-40  md:px-auto px-5  text-white flex flex-col gap-20">
        <div className=" flex  flex-col sm:flex-row items-center gap-16">
          <div className="flex-1">
            <img src={logo} alt="asapuu logo" className=" w-16 mb-5" />
          </div>
          <div className="flex xs:flex-row flex-col justify-between xs:text-left text-center gap-6 flex-1.5">
            {footerItems.map((item, index) => (
              <FooterItem key={index} {...item} />
            ))}
          </div>
        </div>
        <div className="flex justify-between">
          <p className="text-xs">© 2024 — Copyright</p>
          <div className="flex gap-3">
            <p aria-label="Asapuu Twitter">
              <Twitter />
            </p>
            <p aria-label="Asapuu Instagram">
              <Instagram />
            </p>
            <p aria-label="Asapuu Facebook">
              <Facebook />
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
