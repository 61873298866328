import { AppleStore, GooglePlay } from "../../assets/svg";

const AppStoreBtns = () => {
  return (
    <div className="flex gap-3  text-white z-20">
      <button className="flex items-center bg-black rounded-md gap-3 px-3 py-1  sm:w-[200px]">
        <GooglePlay />
        <span className="flex flex-col text-left">
          <span className=" sm:text-base text-[10px] leading-3">Get it on</span>
          <span className="leading-5 sm:text-2xl text-xs">Google Play</span>
        </span>
      </button>
      <button className="flex items-center bg-black rounded-md gap-3 px-3 py-1 sm:w-[200px]">
        <AppleStore />
        <span className="flex flex-col text-left">
          <span className="sm:text-base text-[10px] leading-3">
            Download on
          </span>
          <span className="leading-5 sm:text-2xl text-xs">App Store</span>
        </span>
      </button>
    </div>
  );
};

export default AppStoreBtns;
