import coin from "../../assets/images/about-coin.png";
import PropTypes from "prop-types";

const PageHero = ({ title, info }) => {
  return (
    <div className="relative text-center bg-primary p-5 py-20 flex flex-col items-center justify-center text-white">
      <h1 className="font-mabry-bold xs:text-6xl text-4xl mb-10">{title}</h1>
      <p className="max-w-[350px] xs:text-xl text-base ">{info}</p>
      <img
        src={coin}
        alt="Pink coin"
        className="  absolute right-0  bottom-0 sm:w-[150px] w-[100px]"
      />
    </div>
  );
};

PageHero.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
};

export default PageHero;
