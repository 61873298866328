import AppStoreBtns from "../AppStoreBtns";
import cryptoBro from "../../assets/images/Cryptobro.png";

const FooterCard = () => {
  return (
    <div className=" bg-gray-200 rounded-[50px] shadow-xl absolute md:p-20 p-8 sm:p-14 md:min-h-[300px] min-h-[250px] md:top-[-240px] sm:top-[-200px] top-[-150px] left-0 right-0 flex flex-col sm:gap-5 gap-2 2xl:max-w-[70%] md:max-w-[80%] max-w-[90%] mx-auto overflow-hidden">
      <h5 className="font-mabry-bold xs:text-5xl md:text-6xl text-4xl bg-no-repeat w-fit footer-card-h5">
        Trade on the go!
      </h5>
      <p className=" sm:mb-10 mb-5 text-base sm:text-3xl font-mabry-light">
        Sell crypto and giftcards, pay bills and do{" "}
        <br className=" md:block hidden" /> a whole lot more!
      </p>
      <AppStoreBtns />
      <img
        src={cryptoBro}
        alt="Person chaecking crypto app"
        className=" sm:w-[150px] w-[100px] absolute bottom-0 right-0"
      />
    </div>
  );
};

export default FooterCard;
