import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "../Footer";
import Navbar from "../Navbar";

const helmetContext = {};

const HomeLayout = () => {
  return (
    <HelmetProvider context={helmetContext}>
      <Helmet prioritizeSeoTags>
        <html lang="en" />
        <title>Asapuu</title>
        <meta
          name="description"
          content="Asapuu - Simple crypto trading platform"
        />
        <link rel="canonical" href="https://www.asapuu.com" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Asapuu" />
        <meta property="og:url" content="https://www.asapuu.com" />
        <meta
          property="og:description"
          content="Asapuu - Simple crypto trading platform"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Asapuu" />
        <meta
          name="twitter:description"
          content="Asapuu - Simple crypto trading platform"
        />
      </Helmet>
      <Navbar />
      <section>
        <Outlet />
      </section>
      <Footer />
    </HelmetProvider>
  );
};

export default HomeLayout;
