import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const FooterItem = ({ title, items, link }) => {
  return (
    <div>
      <h6 className="mb-3">{title}</h6>
      <ul>
        {items.map((item, index) => (
          <li key={index} className="break-all font-mabry-light text-sm">
            {link ? (
              <Link
                to={`/${item.toLowerCase().replace(/ /g, "-")}`}
                className="text-white hover:underline"
              >
                {item}
              </Link>
            ) : (
              item
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

FooterItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  link: PropTypes.bool,
};

export default FooterItem;
