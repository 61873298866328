import React from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { companyRoutes } from "./navbar";

const NavDrawer = ({ onClose, open, logo }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleMenuClick = (path) => {
    if (pathname !== path) {
      navigate(path);
    }
  };

  return (
    <div
      className={`xs:hidden flex flex-col items-center justify-center overflow-hidden p-8 gap-5 absolute ${
        open ? "top-0" : "top-[-100%]"
      } left-0 right-0 transition-all duration-500 bg-gray-100 shadow-2xl z-50`}
      onClick={onClose}
    >
      <div>
        <img src={logo} alt="Asapuu logo" className="w-28" />
      </div>
      <div>
        <div className="mb-7">
          <h3 className="font-mabry-bold text-lg">Products</h3>
          <ul className="flex flex-col gap-2">
            <li>Crypto</li>
            <li>Giftcard</li>
            <li>Bill Payments</li>
          </ul>
        </div>
        <div>
          <h3 className="font-mabry-bold text-lg">Company</h3>
          <ul className="flex flex-col gap-2">
            {companyRoutes.map((item) => (
              <li
                key={item.path}
                onClick={() => handleMenuClick(item.path)}
                className="cursor-pointer"
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

NavDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  logo: PropTypes.string.isRequired,
};

export default NavDrawer;
